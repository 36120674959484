<template>
    <div class="form_grp m-0 p-0">
        <div class="group_item">
            <div class="field_wpr">
                <multiselect
                    v-model="filter.duration"
                    :options="options"
                    class="small"
                    value-prop="value"
                    label="label"
                ></multiselect>
            </div>
        </div>
    </div>
    <div class="custom_range" :class="{ active: customRange }">
        <h4>Custom Range</h4>
        <ul>
            <li>
                <label for="">Start Date</label>
                <datepicker v-model="filter.start_date" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
            </li>
            <li>
                <label for="">End Date</label>
                <datepicker v-model="filter.end_date" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
            </li>
            <li>
                <button type="button" class="btn save_btn" @click="updateFilter">Apply</button>
            </li>
        </ul>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import moment from 'moment'

    export default {
        data () {
            return {
                options: [
                    { value: 'Today', label: 'Today' },
                    { value: 'Last 7 Days', label: 'Last 7 Days' },
                    { value: 'Last 14 Days', label: 'Last 14 Days' },
                    { value: 'Last 30 Days', label: 'Last 30 Days' },
                    { value: 'This Month', label: 'This Month' },
                    { value: 'Last Month', label: 'Last Month' },
                    { value: 'This Year', label: 'This Year' },
                    { value: 'All Time', label: 'All Time' },
                    { value: 'Custom Range', label: 'Custom Range' }
                ],
                customRange: false,
                filter: {
                    duration: 'Last 7 Days',
                    start_date: moment.utc().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment.utc().format('YYYY-MM-DD'),
                },
            };
        },

        props: {
            modelValue: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
        },

        watch: {
            'filter.duration' (duration) {
                const vm = this;

                if (duration == 'Today') {
                    vm.filter.start_date  = moment.utc().format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Yesterday') {
                    vm.filter.start_date  = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                } else if (duration == 'Last 7 Days') {
                    vm.filter.start_date  = moment.utc().subtract(7, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Last 14 Days') {
                    vm.filter.start_date  = moment.utc().subtract(14, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Last 30 Days') {
                    vm.filter.start_date  = moment.utc().subtract(30, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'This Month') {
                    vm.filter.start_date  = moment.utc().startOf('month').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Last Month') {
                    vm.filter.start_date  = moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                } else if (duration == 'This Year') {
                    vm.filter.start_date  = moment.utc().startOf('year').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'All Time') {
                    vm.filter.start_date  = '';
                    vm.filter.end_date    = '';
                }

                if (duration == 'Custom Range') {
                    vm.customRange = true;
                } else {
                    vm.customRange = false;
                }

                if (duration != 'Custom Range') {
                    vm.updateFilter();
                }
            },
        },

        methods: {
            updateFilter () {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.modelValue));

                vm.customRange    = false;
                params.duration   = vm.filter.duration;
                params.start_date = vm.filter.start_date;
                params.end_date   = vm.filter.end_date;

                vm.$emit('update:modelValue', params);
            },
        },
    }
</script>

<style lang="css" scoped>
</style>
